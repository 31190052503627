import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import { METRIC_EARNINGS } from "constants/types";
import { numberWithCommas } from 'utility/utils';


const styles = (theme) => ({
  root: {
    display: "-webkit-box",
    // width: 356,
    width: "100%",
    // maxWidth: "100%",
    margin: theme.spacing(1),
    marginTop: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    cursor: "pointer",
    background: theme.palette.background.main,
    boxShadow: `0 1px 1px 1px rgba(63,63,68,0.05), 0 3px 3px 2px rgba(63,63,68,0.15)`,
  },
  container: {
  },
  ranking: {
    fontSize: '20px',
    fontStyle: 'italic',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  avatar: {
    minWidth: 72,
  },
  image: {
    objectFit: "cover",
    height: 72,
    width: 72,
  },
  text: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.text.primary
  },
  name: {
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: theme.spacing(1),
    color: theme.palette.text.primary
  },
  description: {
    fontSize: "12px",
    lineHeight: "14px",
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  title: {
    fontSize: "14px",
    fontWeight: 500,
    color: theme.palette.text.primary
  },
  earnings: {
    fontSize: "14px",
    fontWeight: 500,
    marginLeft: theme.spacing(2),
    color: theme.palette.text.primary
  },
  album_image: {
    objectFit: "cover",
    marginLeft: theme.spacing(2),
    height: 56,
    width: 56,
    borderRadius: 4,
  }
});

class TopsongCard extends React.Component {

  render() {
    const { classes, artist, onClickArtist } = this.props;

    const topsong = artist.topsongTrack !== null ? artist.topsongTrack.name : null;
    const topsongCover = artist.topsongTrack !== null ? artist.topsongTrack.coverArt : null;

    const topsongEarnings = Math.floor(artist.topsongPlaycount * METRIC_EARNINGS);

    return (
      <Card className={classes.root}>
        <CardActionArea onClick={e => onClickArtist(artist)}>
          <CardContent>
            <ListItem alignItems="flex-start"
              key={`${artist.id}-topsong`}
              ContainerComponent="div"
            >
              <ListItemAvatar className={classes.avatar}>
                <img
                  className={classes.image}
                  alt={topsong}
                  src={topsongCover}
                />
              </ListItemAvatar>
              <ListItemText
                id={`${artist.id}-topsong`}
                className={classes.text}
                primary={
                  <div>
                    <Typography className={classes.name}>
                      {topsong}
                    </Typography>
                    <Typography className={classes.description}>
                      Top Song Earnings
                    </Typography>
                    <Typography className={classes.earnings}>
                      $ {numberWithCommas(topsongEarnings)}
                    </Typography>
                  </div>
                }
              />
              <ListItemSecondaryAction>
                <Typography className={classes.ranking}>
                  {artist.topsongRank}
                </Typography>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem 
              className={classes.container} 
              key={artist.id}
              ContainerComponent="div"
            >
              <ListItemAvatar className={classes.avatar}>
                <img
                  className={classes.album_image}
                  alt={artist.name}
                  src={artist.avatarImage ? artist.avatarImage : "/static/images/blank_avatar.png"}
                />
              </ListItemAvatar>
              <ListItemText
                id={`${artist.id}`}
                className={classes.text}
                primary={
                  <div>
                    <Typography className={classes.title}>
                      {artist.name}
                    </Typography>
                    <Typography className={classes.description}>
                      Monthly Listeners &emsp;&nbsp;{numberWithCommas(artist.monthlyListeners)}
                    </Typography>
                    <Typography className={classes.description}>
                      Top Song Streams &emsp;&nbsp;{numberWithCommas(artist.topsongPlaycount)}
                    </Typography>
                  </div>
                }
              />
            </ListItem>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

TopsongCard.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(TopsongCard);
