export const LANDING                = '/';
export const SIGN_UP                = '/sign-up';
export const SIGN_IN                = '/sign-in';
export const RESET_PASSWORD         = '/resetpassword';
export const HOME                   = '/home';
export const NOT_FOUND              = '/not-found';
// export const GENRES                 = '/genres';
export const RANKINGS               = '/rankings';
export const TOPSONGS               = '/topsongs';
export const TOPEARNERS             = '/topearners';
export const SEARCHRESULTS          = '/search';
export const ARTIST                 = '/artist/:artist_slug';
// export const GENRE_RANKINGS         = '/genres/:genre_slug/rankings';
// export const GENRE_TOPSONGS         = '/genres/:genre_slug/topsongs';
// export const GENRE_TOPEARNERS       = '/genres/:genre_slug/topearners';
// export const GENRE_ARTIST           = '/genres/:genre_slug/artist/:artist_slug';
export const PRIVACY_POLICY         = '/privacypolicy';
export const COOKIE_POLICY          = '/cookiepolicy';
export const TERMS_CONDITIONS       = '/terms';
export const WAITLIST               = '/waitlist';