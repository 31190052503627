import * as ActionTypes from "./ActionTypes";
import { connect } from 'services/socketService';


export const connectSocket = () => (dispatch) => {
    const socket = connect();
    dispatch({ 
        type: ActionTypes.SOCKET_CONNECT, payload: socket 
    });
};

export const disconnectSocket = () => ({
    type: ActionTypes.SOCKET_DISCONNECT,
});
