import * as ActionTypes from "actions/ActionTypes";

const INTIAL_STATE = {
  socket: null,
};

const socketReducer = (state = INTIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SOCKET_CONNECT: {
      return {
        ...state,
        socket: action.payload,
      };
    }

    case ActionTypes.SOCKET_DISCONNECT: {
      state.socket && state.socket.disconnect();
      return INTIAL_STATE;
    }
    
    default: {
      return state;
    }
  }
};

export default socketReducer;
