import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { 
  Grid, 
  Typography 
} from '@material-ui/core';
import { 
  COOKIE_POLICY, 
  PRIVACY_POLICY, 
  TERMS_CONDITIONS 
} from 'constants/types';
import * as ROUTES from "constants/routes";

const styles = theme => ({
  root: {
    position: "relative",
    // width: `calc(100% - 32px)`,
    bottom: '0',
    backgroundColor: theme.palette.background.main,
  },
  toc: {
    textTransform: "none",
    fontSize: 14,
    lineHeight: '24px',
    fontWeight: 400,
    color: "#255a8e",
    cursor: "pointer",
    padding: "1rem"
  }
});

class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickPolicy = this.handleClickPolicy.bind(this);
  }

  handleClickPolicy = (policy) => {
    console.log("policy :", policy);

    if (policy === PRIVACY_POLICY) {
      this.props.history.push(ROUTES.PRIVACY_POLICY);
    } else if (policy === COOKIE_POLICY) {
      this.props.history.push(ROUTES.COOKIE_POLICY);
    } else if (policy === TERMS_CONDITIONS) {
      this.props.history.push(ROUTES.TERMS_CONDITIONS);
    }

    this.createPolicy(document, 'script', 'termly-jssdk');
  }

  createPolicy = (d, s, id) => {
    var element = d.getElementById(id);
    if (element) {
      element.parentNode.removeChild(element);
    };

    var js, tjs = d.getElementsByTagName(s)[0];

    js = d.createElement(s); 
    js.id = id;
    js.src = "https://app.termly.io/embed-policy.min.js";
    
    tjs.parentNode.insertBefore(js, tjs);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center"
          // spacing={4}
        >
          <Grid item>
            <Typography className={classes.toc} onClick={e => this.handleClickPolicy(PRIVACY_POLICY)}>
              Privacy Policy
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.toc} onClick={e => this.handleClickPolicy(COOKIE_POLICY)}>
              Cookie Policy
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.toc} onClick={e => this.handleClickPolicy(TERMS_CONDITIONS)}>
              Terms and Conditions
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string
};

export default compose(
  withRouter,
  withStyles(styles)
)(Footer);
