import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Hidden, Container } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 120,
    // [theme.breakpoints.up("sm")]: {
    //   top: "180px",
    // },
    margin: theme.spacing(2),
  },
  mobilecontainer: {
    position: "relative",
    paddingTop: 120,
  },
});

class CookiePolicy extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <div className={classes.maincontainer}>
            <Container>
              <h1 style={{ textAlign: "center" }}>Cookie Policy</h1>

              <p style={{ lineHeight: "2rem" }}>
                <b>1. Cookies And Similar Technologies</b>
                <br></br>
                The acceptance of cookies is not a requirement to visit our website. Like most online services, we use cookies to help us understand how people are using MusicFutures, so that we can continue to improve the service we offer. Moreover, some of our features are only possible if you allow us to set cookies. In this section, we explain our use of cookies, and how you can disable them.
              </p>
              <p style={{ lineHeight: "2rem" }}>
                <b>1.1 What are cookies</b>
                <br></br>
                Cookies are small text files that are placed on your computer by websites that you visit. These text files can be read by these websites and help to identify you when you return to a website. There are "persistent" and "session" cookies. Persistent cookies remain on your computer when you have gone offline, while session cookies are deleted as soon as you close your web browser. To find out more about cookies, including how to see what cookies have been set and how to block and delete cookies, please visit http://www.aboutcookies.org. You can at any time manually delete cookies or deactivate the usage of cookies in the settings of your internet browser.
              </p>
              <p style={{ lineHeight: "2rem" }}>
                <b>1.2 MusicFutures cookies</b>
                <br></br>
                We use our own cookies to recognize you when you visit our website or use our service on your mobile device. This means that you don't have to log in each time you visit, and we can remember your preferences and settings.
              </p>

              <p style={{ lineHeight: "2rem" }}>
                <b>1.3 Third Party Cookies / Similar Technologies</b>
                <br></br>
                In addition to our own cookies, we work with different reputable companies to help us analyze how MusicFutures is used, and improve our service to deliver the best possible experience. The following companies help us achieve this through use of cookies:
                Google Analytics and Google Website Optimizer
                <br></br>
                <br></br>
                Google Analytics and Google Website Optimizer are services provided by Google, Inc. (“Google”). Google Analytics uses cookies to help us analyze how users use our services. Google Web Optimizer uses the same cookies to measure how different users respond to different content. The information generated by these cookies (including your truncated IP address) is transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your and other users' use of our services and providing other functionality related to website activity and internet usage. Please note that Google only receives your truncated IP address. This is sufficient for Google to identify (approximately) the country from which you are visiting our service, but is not sufficient to identify you, your computer or mobile device, individually.
                <br></br>
                <br></br>
                You can find more information here, <a href="www.google.com/intl/en/policies/privacy/">www.google.com/intl/en/policies/privacy/</a>.
              </p>

              <p style={{ lineHeight: "2rem" }}>
                <b>1.4 How to manage your cookies and preferences</b>
                <br></br>
                Most browsers accept cookies by default. You can use the settings within your browser to control the cookies that are set on your computer or mobile device.
                <br></br>
                <br></br>
                This Cookie Policy is effective from 1st January 2024
              </p>
            </Container>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.mobilecontainer}>
            <Container>
              <h1 style={{ textAlign: "center" }}>Cookie Policy</h1>

              <p style={{ lineHeight: "2rem" }}>
                <b>1. Cookies And Similar Technologies</b>
                <br></br>
                The acceptance of cookies is not a requirement to visit our website. Like most online services, we use cookies to help us understand how people are using MusicFutures, so that we can continue to improve the service we offer. Moreover, some of our features are only possible if you allow us to set cookies. In this section, we explain our use of cookies, and how you can disable them.
              </p>
              <p style={{ lineHeight: "2rem" }}>
                <b>1.1 What are cookies</b>
                <br></br>
                Cookies are small text files that are placed on your computer by websites that you visit. These text files can be read by these websites and help to identify you when you return to a website. There are "persistent" and "session" cookies. Persistent cookies remain on your computer when you have gone offline, while session cookies are deleted as soon as you close your web browser. To find out more about cookies, including how to see what cookies have been set and how to block and delete cookies, please visit http://www.aboutcookies.org. You can at any time manually delete cookies or deactivate the usage of cookies in the settings of your internet browser.
              </p>
              <p style={{ lineHeight: "2rem" }}>
                <b>1.2 MusicFutures cookies</b>
                <br></br>
                We use our own cookies to recognize you when you visit our website or use our service on your mobile device. This means that you don't have to log in each time you visit, and we can remember your preferences and settings.
              </p>

              <p style={{ lineHeight: "2rem" }}>
                <b>1.3 Third Party Cookies / Similar Technologies</b>
                <br></br>
                In addition to our own cookies, we work with different reputable companies to help us analyze how MusicFutures is used, and improve our service to deliver the best possible experience. The following companies help us achieve this through use of cookies:
                Google Analytics and Google Website Optimizer
                <br></br>
                <br></br>
                Google Analytics and Google Website Optimizer are services provided by Google, Inc. (“Google”). Google Analytics uses cookies to help us analyze how users use our services. Google Web Optimizer uses the same cookies to measure how different users respond to different content. The information generated by these cookies (including your truncated IP address) is transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your and other users' use of our services and providing other functionality related to website activity and internet usage. Please note that Google only receives your truncated IP address. This is sufficient for Google to identify (approximately) the country from which you are visiting our service, but is not sufficient to identify you, your computer or mobile device, individually.
                <br></br>
                <br></br>
                You can find more information here, <a href="www.google.com/intl/en/policies/privacy/">www.google.com/intl/en/policies/privacy/</a>.
              </p>

              <p style={{ lineHeight: "2rem" }}>
                <b>1.4 How to manage your cookies and preferences</b>
                <br></br>
                Most browsers accept cookies by default. You can use the settings within your browser to control the cookies that are set on your computer or mobile device.
                <br></br>
                <br></br>
                This Cookie Policy is effective from 1st January 2024
              </p>
            </Container>
          </div>
        </Hidden>
      </div>
    );
  }
}

CookiePolicy.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(CookiePolicy);
