import React from 'react';
import PropTypes from 'prop-types';
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from '@material-ui/core/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  Typography,
  TablePagination,
} from '@material-ui/core';
import { TAB_ARTIST } from 'constants/types';
import {
  getArtistCount,
  getArtists,
  calculateCatalogEarnings 
} from "services/artistServices";
import {
  getGenreArtistCount,
  getGenreArtists
} from "services/genreServices";
import { numberWithCommas } from 'utility/utils';


const styles = theme => ({
  root: {},
  content: {
    padding: 0,
    backgroundColor: theme.palette.secondary.main
  },
  container: {
    width: '100%',
    border: '1px solid black',
  },
  table: {
    width: '100%',
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: 56,
    height: 56
  },
  actions: {
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.secondary.main
  },
  headeritemstyle: {
    paddingRight: theme.spacing(2),
    padding: 8,
    borderRight: '1px solid black',
  },
  headerlastitemstyle: {
    paddingRight: theme.spacing(2),
    padding: 8,
  },
  row: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    padding: 4,
    cursor: 'pointer',
  },
  itemstyle: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    padding: 4,
  },
});


const headCells = [
  { id: 'rank', numeric: true, disablePadding: false, label: '# (Rank)' },
  { id: 'artist', numeric: false, disablePadding: false, label: 'Artist' },
  { id: 'monthlylisteners', numeric: true, disablePadding: false, label: 'Monthly Listeners' },
  { id: 'followers', numeric: true, disablePadding: false, label: 'Followers' },
  { id: 'latestrelease', numeric: false, disablePadding: false, label: 'Latest Release'},
  { id: 'topsong', numeric: false, disablePadding: false, label: 'Top Song' },
  { id: 'catalogearnings', numeric: false, disablePadding: false, label: 'Catalog Earnings' },
];

class ArtistRankingTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rowsPerPage   : 25,
      page          : 0,
      order         : 'asc',
      orderBy       : 'rank'
    };

    this.handleClick = this.handleClick.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this);

    this.handleSort = this.handleSort.bind(this);
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  componentDidMount = async() => {
    const { selected_genre, page_no, page_size, artists } = this.props;
    this.setState({
      ...this.state,
      rowsPerPage: page_size,
      page: page_no
    });
    if (artists.length === 0) {
      await this.getArtistsOfPage(selected_genre, page_no, page_size);
    }
  }

  handleClick = artist_id => {
    const { artists } = this.props;
    const selected_artist = artists.find(artist => artist.id === artist_id);
    if (selected_artist === undefined) {
      return;
    }

    this.props.selectArtist(selected_artist);
    this.props.setCurrentTab(TAB_ARTIST);
    this.props.onClickArtist(selected_artist.slug);
  }

  handlePageChange = (event, page) => {
    event.preventDefault();
    
    this.setState({
      ...this.state,
      page: page
    });

    this.props.setPageNo(page);

    // get artists of this page
    const { selected_genre, page_size } = this.props;
    this.getArtistsOfPage(selected_genre, page, page_size);
  };

  handleRowsPerPageChange = event => {
    const pagesize = event.target.value;
    this.setState({
      ...this.state,
      page: 0,
      rowsPerPage: pagesize
    });

    this.props.setPagesize(pagesize);

    // get artists of this page
    const { selected_genre, page_no } = this.props;
    this.getArtistsOfPage(selected_genre, page_no, pagesize);
  };

  handleSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState(Object.assign({}, this.state, {
      order: isAsc ? 'desc' : 'asc',
      orderBy: property
    }));
  }

  descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }
  
  stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  getArtistsOfPage = async (genre, pageNo, pageSize) => {
    this.setWaiting(true);

    // console.log("Get Artist page :", genre, pageNo, pageSize);

    let artist_count = 0;
    let artists = [];
    if (genre !== null) {
      artist_count = await getGenreArtistCount(genre.id);
      artists = await getGenreArtists(genre.id, "ranking", pageSize, pageNo * pageSize);
      let index = 1;
      for (let artist of artists) {
        artist.genre_index = pageNo * pageSize + index;
        index ++;
      }
    } else {
      artist_count = await getArtistCount();
      console.log(`Ranking pageSize=${pageSize}, pageNo=${pageNo}`);
      artists = await getArtists("ranking", pageSize, pageNo * pageSize);
      console.log("got artists :", artists);
    }

    this.props.setArtistCount(artist_count);
    this.props.setArtists(artists);
    console.log(artists)
    this.setWaiting(false);
  }
  
  render() {
    const { classes, artists, artist_count, selected_genre } = this.props;
    const { page, rowsPerPage, order, orderBy } = this.state;

    if (artist_count === 0 || artists.length === 0) {
      return <div></div>;
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, artist_count - page * rowsPerPage);

    const artistData = artists.map(artist => {
      const topsong = artist.topsongTrack !== null ? artist.topsongTrack.name : null;
      const topsongCover = artist.topsongTrack !== null ? artist.topsongTrack.coverArt : null;
      let latestrelease = null;
      let latestCover = null;
      if (artist.latest !== null && artist.latest.name !== undefined) {
        latestrelease = `${artist.latest.name} - ${artist.latest.date.year}`;
        if (artist.latest.coverArt !== undefined) {
          latestCover = artist.latest.coverArt.sources[0].url;
        }
      }
      let catalogEarnings = calculateCatalogEarnings(artist.alltimeStreams);

      let item = {
        id: artist.id,
        rank: selected_genre === null ? artist.worldRank : artist.genre_index,
        name: artist.name,
        image: artist.avatarImage,
        monthlylisteners: artist.monthlyListeners,
        followers: artist.followers,
        latestrelease: latestrelease,
        latestCover: latestCover,
        topsong: topsong,
        topsongCover: topsongCover,
        catalogearnings: catalogEarnings
      };

      return item;
    });

    console.log("artistData in table :", artistData);

    return (
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <TableContainer className={classes.container}>
              <Table className={classes.table} stickyHeader aria-label="sticky table" size={"small"}>
                <TableHead className={classes.head}>
                  <TableRow className={classes.itemstyle}>
                     {headCells.map(headCell => (
                      <TableCell className={headCell.id === 'chart' ? classes.headerlastitemstyle : classes.headeritemstyle}
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <Tooltip enterDelay={300} title={orderBy === headCell.id ? (order === 'desc' ? 'sorted descending' : 'sorted ascending') : ''}>
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={event => this.handleSort(event, headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody className={classes.body}>
                  {this.stableSort(artistData, this.getComparator(order, orderBy))
                    .map((artist, index) => {

                      return (
                        <TableRow
                          className={classes.row}
                          hover
                          key={artist.id}
                          onClick={event => this.handleClick(artist.id)}
                        >
                          <TableCell className={classes.itemstyle} align="center">
                            {artist.rank}
                          </TableCell>
                          <TableCell className={classes.itemstyle} component="th" scope="row" padding="none">
                            <div className={classes.nameContainer}>
                              <Avatar variant="rounded" className={classes.avatar} src={artist.image}>
                                {artist.name}
                              </Avatar>
                              <Typography variant="h5">{artist.name}</Typography>
                            </div>
                          </TableCell>
                          <TableCell className={classes.itemstyle}>
                            {numberWithCommas(artist.monthlylisteners)}
                          </TableCell>
                          <TableCell className={classes.itemstyle}>
                            {numberWithCommas(artist.followers)}
                          </TableCell>
                          <TableCell className={classes.itemstyle}>
                            <div className={classes.nameContainer}>
                              {artist.latestCover !== null && 
                              <Avatar variant="rounded" className={classes.avatar} src={artist.latestCover}>
                                {artist.latestrelease}
                              </Avatar>
                              }
                              <Typography>{artist.latestrelease}</Typography>
                            </div>
                          </TableCell>
                          <TableCell className={classes.itemstyle}>
                            <div className={classes.nameContainer}>
                              {artist.topsongCover !== null &&
                              <Avatar variant="rounded" className={classes.avatar} src={artist.topsongCover}>
                                {artist.topsong}
                              </Avatar>
                              }
                              <Typography>{artist.topsong}</Typography>
                            </div>
                          </TableCell>
                          <TableCell className={classes.itemstyle}>
                            {`$ ${artist.catalogearnings}`}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={headCells.length + 1} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={artist_count}
            page={page}
            onPageChange={this.handlePageChange}
            onRowsPerPageChange={this.handleRowsPerPageChange}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[25, 50, 100]}
            // ActionsComponent={TablePaginationActions}
          />
        </CardActions>
      </Card>
    );
  }
};

ArtistRankingTable.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  requesting: state.uiState.requesting,
  page_no: state.uiState.page_no,
  page_size: state.uiState.page_size,
  selected_genre: state.dataState.selected_genre,
  artists: state.dataState.artists,
  artist_count: state.dataState.artist_count,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(ArtistRankingTable);
