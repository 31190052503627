import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Typography, Button } from "@material-ui/core";
import {
  SearchInput,
  WaitingDialog,
  SnackAlert
} from "components";
import * as ROUTES from "constants/routes";
import {
  TAB_RANKINGS,
  TAB_TOPSONGS,
  TAB_TOPEARNERS,
  TAB_SEARCHRESULT
} from "constants/types";
// import { getGenres } from "services/genreServices";
import { getArtistsByName } from "services/artistServices";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 100,
    // top: "180px",
    [theme.breakpoints.down("sm")]: {
      // top: "180px",
      paddingTop: 100,
    },
    padding: theme.spacing(2),
  },
  title_container: {
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: "64px",
    color: "#3579b8",
    lineHeight: "72px",
    fontWeight: 700,
    textTransform: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: "44px",
      fontWeight: "48px",
    },
  },
  search_container: {
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      height: '28px',
    }
  },
  search_div: {
    borderColor: '1px solid black',
  },
  button_container: {
    marginTop: theme.spacing(4),
  },
  button: {
    fontFamily: "Montserrat, sans-serif",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textTransform: 'none',
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Montserrat, sans-serif",
    },
    color: "#3579b8",
    borderColor: "#3579b8",
  },
  music_analytics: {
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: '3rem',
    backgroundColor: "#BAC6D2",
    padding: "2rem",
    borderRadius: "1rem",
    color: "#3579b8",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      width: "25rem",
      height: "17rem",
    },
  },
  music_gaming: {
    textAlign: "center",
    fontFamily: "Montserrat",
    marginTop: "3rem",
    backgroundColor: "#3579b8",
    padding: "2rem",
    borderRadius: "1rem",
    color: "white",
    [theme.breakpoints.up("sm")]: {
      width: "25rem",
      height: "17rem",
    },    
  },
  btn_blue: {
    backgroundColor: "#3579b8",
    color: "white",
    borderRadius: "0.5rem",
    width: "10rem",
    padding: "0.5rem",
    border: "none",
    fontFamily: "Montserrat",
    fontWeight: "600",
    cursor: "pointer"
  },
  btn_white: {
    backgroundColor: "white",
    color: "#3579b8",
    borderRadius: "0.5rem",
    width: "10rem",
    padding: "0.5rem",
    border: "none",
    fontFamily: "Montserrat",
    fontWeight: "600",
    cursor: "pointer"
  }
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTxt: "",
    };

    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleWaitlistClick = this.handleWaitlistClick.bind(this);
    this.handleClickButton = this.handleClickButton.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
  }

  componentDidMount = () => {
    if (this.checkOnline()) {
      this.initialize();
    } else {
      this.props.setError("Internet disconnected! Check your internet connection.");
    }
    window.scrollTo(0, 0);
  }

  setError = (message) => {
    this.props.setError(message);
    this.props.requestDataFinished();
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  checkOnline = () => {
    return window.navigator.onLine ? true : false;
  }

  initialize = async () => {
    // this.setWaiting(true);

    // // Get Genres
    // try {
    //   const genres = await getGenres();
    //   this.props.setGenres(genres);
    // } catch (err) {
    //   this.setError(err.message);
    // }

    // this.setWaiting(false);
  }

  _getAuthToken = async () => {
    const { authUser } = this.props;
    let token = authUser.token;
    return token;
  }

  handleCloseError = () => {
    this.props.clearError();
  }

  handleSearchChange = event => {
    this.setState({
      ...this.state,
      searchTxt: event.target.value
    });
  };

  handleSearch = event => {
    if (event.key === "Enter") {
      const searchkey = this.state.searchTxt.trim();
      if (searchkey.length > 0) {
        this.props.setSearchKey(searchkey);
        this.doSearch(searchkey);
      }
    }
  };

  doSearch = async (searchkey) => {
    this.setWaiting(true);

    // Get Artists
    try {
      const artists = await getArtistsByName(searchkey);
      this.props.setSearchResult(artists);
      this.props.setPageNo(0);
      this.props.setCurrentTab(TAB_SEARCHRESULT);

      this.props.history.push(ROUTES.SEARCHRESULTS);
    } catch (err) {
      this.setError(err.message);
    }

    this.setWaiting(false);
  }

  handleClickButton = (tabValue) => {
    this.props.selectGenre(null);
    this.props.setSearchKey("");
    this.props.setArtists([]);
    this.props.setArtistsList([]);
    this.props.setPageNo(0);

    let route = ROUTES.HOME;
    // if (tabValue === TAB_GENRES) {
    //   route = ROUTES.GENRES;
    // }
    if (tabValue === TAB_RANKINGS) {
      route = ROUTES.RANKINGS;
    } else if (tabValue === TAB_TOPSONGS) {
      route = ROUTES.TOPSONGS;
    } else if (tabValue === TAB_TOPEARNERS) {
      route = ROUTES.TOPEARNERS;
    }

    this.props.history.push(route);
    this.props.setCurrentTab(tabValue);
  }

  handleWaitlistClick = () => {
    let route = ROUTES.WAITLIST;
    this.props.history.push(route);
  }

  render() {
    const {
      classes,
      requesting,
      error,
      errorMsg,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.maincontainer}>
          <div className={classes.title_container}>
            <Typography className={classes.title}>
              {"MUSIC FUTURES"}
            </Typography>
          </div>

          <Grid container
            justifyContent="center"
            className={classes.search_container}
          >
            <Grid item>
              <Hidden smDown implementation="css">
                <div className={classes.search_div}>
                  <SearchInput
                    style={{ width: 640 }}
                    onChange={this.handleSearchChange}
                    onKeyPress={this.handleSearch}
                  />
                </div>
              </Hidden>
              <Hidden mdUp implementation="css">
                <div className={classes.search_div}>
                  <SearchInput
                    style={{ width: 320 }}
                    onChange={this.handleSearchChange}
                    onKeyPress={this.handleSearch}
                  />
                </div>
              </Hidden>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item style={{ width: "640px", textAlign: "center" }}>
              <small style={{ fontFamily: "Montserrat", color: "#3579b8" }}>MusicFutures.com is currently in open beta. Use our free analytics tool to search and discover music streaming statistics about your favorite artists.</small>
            </Grid>
          </Grid>
          <Grid container
            justifyContent="center" spacing={2}
            className={classes.button_container}
          >
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                onClick={e => this.handleClickButton(TAB_RANKINGS)}
              >
                Rankings
              </Button>
            </Grid>
            {/* <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                onClick={e => this.handleClickButton(TAB_GENRES)}
              >
                Genres
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                onClick={e => this.handleClickButton(TAB_TOPSONGS)}
              >
                Top Songs
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                variant="outlined"
                onClick={e => this.handleClickButton(TAB_TOPEARNERS)}
              >
                Top Earners
              </Button>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" spacing={2}>
            <Grid item>
              <div className={classes.music_analytics}>
                <h2>Music Analytics Tool</h2>
                <p>Our music analytics tool tracks streaming music statistics for the top 2,000 artists on Spotify. Use our free tool to discover global rankings, streaming trends and catalog earnings estimates.</p>
                <button
                  className={classes.btn_blue}
                  onClick={e => this.handleClickButton(TAB_RANKINGS)}
                >
                  USE OUR TOOL
                </button>
              </div>
            </Grid>
            <Grid item>
              <div className={classes.music_gaming}>
                <h2>Music Gaming Platform</h2>
                <p style={{ margin: "1rem 0" }}>Music Futures is developing a next generation real-money music fantasy game. Compete to win cash & prizes by predicting the streaming performances of popular artists and bands!</p>
                <button
                  className={classes.btn_white}
                  onClick={(e) => this.handleWaitlistClick()}
                >
                  LEARN MORE
                </button>
              </div>
            </Grid>
          </Grid>
        </div>
        <WaitingDialog open={requesting} />
        {error && (
          <SnackAlert
            variant={"error"}
            message={errorMsg}
            openStats={error}
            vertical={"top"}
            horizontal={"center"}
            handleEvent={this.handleCloseError}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  requesting: state.uiState.requesting,
  error: state.uiState.error,
  errorMsg: state.uiState.errorMsg
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Home);
