import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Hidden, Container } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 120,
    // [theme.breakpoints.up("sm")]: {
    //   top: "180px",
    // },
    margin: theme.spacing(2),
  },
  mobilecontainer: {
    position: "relative",
    paddingTop: 120,
  },
});

class TermsConditions extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <div className={classes.maincontainer}>
            <Container style={{ marginTop: "2rem" }}>

              <h1 style={{ textAlign: "center" }}>TERMS OF SERVICE</h1>


              <p style={{ lineHeight: "2rem" }}>
                <b>1. Introduction</b>
                <br></br>
                Your privacy is important to us. We are not here to collect data, but to create a fun platform for creators and music fans! This Privacy Policy explains how we collect, use, share and secure your information when you use MusicFutures.com. Any information provided by you will only be used in accordance with this Privacy Policy.

                If you wish to object to the collection, processing or use of your data by us in accordance with this Privacy Policy as a whole or for individual measures, you can send your objection at any time by e-mail to the following contact details:

                Music Futures Inc.
                info@musicfutures.com</p>

              <p style={{ lineHeight: "2rem" }}>
                <b>2. Our Principles</b>
                <br></br>
                We are committed to two main principles: Privacy Protection and Transparency.

                We are committed to ensure that your privacy is protected. Therefore, we are very careful with user-data. Data is fully enclosed and encrypted, providing you with the highest level of privacy security possible.

                We are committed to ensure that you know exactly why we need your data and what is happening with the information we collect. You should have full control over your information. In case you have any questions concerning your information or this Privacy Policy, please feel free to contact us at any time!
              </p>


              <p style={{ lineHeight: "2rem" }}>
                <b>3. Contact Us</b>
                <br></br>
                In case you have any questions regarding this Terms of Service, or the use or storage of your information, please feel free to contact us at any time under info@musicfutures.com.

                This Terms of Service is effective from 1st January 2024.
              </p>
            </Container>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.mobilecontainer}>
            <Container style={{ marginTop: "2rem" }}>

              <h1 style={{ textAlign: "center" }}>TERMS OF SERVICE</h1>


              <p style={{ lineHeight: "2rem" }}>
                <b>1. Introduction</b>
                <br></br>
                Your privacy is important to us. We are not here to collect data, but to create a fun platform for creators and music fans! This Privacy Policy explains how we collect, use, share and secure your information when you use MusicFutures.com. Any information provided by you will only be used in accordance with this Privacy Policy.

                If you wish to object to the collection, processing or use of your data by us in accordance with this Privacy Policy as a whole or for individual measures, you can send your objection at any time by e-mail to the following contact details:

                Music Futures Inc.
                info@musicfutures.com</p>

              <p style={{ lineHeight: "2rem" }}>
                <b>2. Our Principles</b>
                <br></br>
                We are committed to two main principles: Privacy Protection and Transparency.

                We are committed to ensure that your privacy is protected. Therefore, we are very careful with user-data. Data is fully enclosed and encrypted, providing you with the highest level of privacy security possible.

                We are committed to ensure that you know exactly why we need your data and what is happening with the information we collect. You should have full control over your information. In case you have any questions concerning your information or this Privacy Policy, please feel free to contact us at any time!
              </p>


              <p style={{ lineHeight: "2rem" }}>
                <b>3. Contact Us</b>
                <br></br>
                In case you have any questions regarding this Terms of Service, or the use or storage of your information, please feel free to contact us at any time under info@musicfutures.com.

                This Terms of Service is effective from 1st January 2024.
              </p>
            </Container>
          </div>
        </Hidden>
      </div>
    );
  }
}

TermsConditions.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(TermsConditions);
