import * as ActionTypes from "./ActionTypes";

export const selectThemeMode = mode => dispatch => {
  dispatch({
    type: ActionTypes.SELECT_THEME_MODE,
    mode
  });
}

export const showSplashScreen = show => dispatch => {
  dispatch({
    type: ActionTypes.SHOW_SPLASH_SCREEN,
    show
  });
}

export const requestDataPending = () => dispatch => {
  dispatch({
    type: ActionTypes.REQUEST_DATA_PENDING
  });
}

export const requestDataFinished = () => dispatch => {
  dispatch({
    type: ActionTypes.REQUEST_DATA_FINISHED
  });
}

export const setError = (errorMsg) => dispatch => {
  dispatch({
    type: ActionTypes.SET_ERROR,
    errorMsg
  });
}

export const clearError = () => dispatch => {
  dispatch({
    type: ActionTypes.CLEAR_ERROR
  });
}

export const setPageNo = (pageNo) => dispatch => {
  dispatch({
    type: ActionTypes.SET_PAGE_NO,
    pageNo
  });
}

export const setPagesize = (pagesize) => dispatch => {
  dispatch({
    type: ActionTypes.SET_PAGE_SIZE,
    pagesize
  });
}

export const initScrollPos = () => dispatch => {
  dispatch({ 
    type: ActionTypes.INIT_SCROLL_POS 
  });
};

export const saveScrollPos = (X, Y) => dispatch => {
  dispatch({ 
    type: ActionTypes.SAVE_SCROLL_POS, 
    pos: { x: X, y: Y } 
  });
};
