import React from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout, Policy as PolicyLayout } from './layouts';

import {
  Home as HomeView,
  // Genres as GenresView,
  Rankings as RankingsView,
  TopSongs as TopSongsView,
  TopEarners as TopEarnersView,
  SearchResult as SearchResultView,
  Artist as ArtistView,
  SignIn as SignInView,
  SignUp as SignUpView,
  ResetPassword as ResetPasswordView,
  NotFound as NotFoundView,
  PrivacyPolicy as PrivacyPolicyView,
  CookiePolicy as CookiePolicyView,
  TermsConditions as TermsConditionsView,
  Waitlist as WaitlistView
} from "./views";


const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Redirect
          exact
          from={ROUTES.LANDING}
          to={ROUTES.HOME}
        />
        <RouteWithLayout
          component={HomeView}
          exact
          layout={MinimalLayout}
          path={ROUTES.HOME}
        />
        {/* <RouteWithLayout
          component={GenresView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRES}
        /> */}
        <RouteWithLayout
          component={RankingsView}
          exact
          layout={MainLayout}
          path={ROUTES.RANKINGS}
        />
        {/* <RouteWithLayout
          component={RankingsView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRE_RANKINGS}
        /> */}
        <RouteWithLayout
          component={TopSongsView}
          exact
          layout={MainLayout}
          path={ROUTES.TOPSONGS}
        />
        {/* <RouteWithLayout
          component={TopSongsView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRE_TOPSONGS}
        /> */}
        <RouteWithLayout
          component={TopEarnersView}
          exact
          layout={MainLayout}
          path={ROUTES.TOPEARNERS}
        />
        {/* <RouteWithLayout
          component={TopEarnersView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRE_TOPEARNERS}
        /> */}
        <RouteWithLayout
          component={SearchResultView}
          exact
          layout={MainLayout}
          path={ROUTES.SEARCHRESULTS}
        />
        <RouteWithLayout
          component={ArtistView}
          exact
          layout={MainLayout}
          path={ROUTES.ARTIST}
        />
        {/* <RouteWithLayout
          component={ArtistView}
          exact
          layout={MainLayout}
          path={ROUTES.GENRE_ARTIST}
        /> */}
        <RouteWithLayout
          component={PrivacyPolicyView}
          exact
          layout={PolicyLayout}
          path={ROUTES.PRIVACY_POLICY}
        />
        <RouteWithLayout
          component={CookiePolicyView}
          exact
          layout={PolicyLayout}
          path={ROUTES.COOKIE_POLICY}
        />
        <RouteWithLayout
          component={TermsConditionsView}
          exact
          layout={PolicyLayout}
          path={ROUTES.TERMS_CONDITIONS}
        />
        <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path={ROUTES.SIGN_UP}
        />
        <RouteWithLayout
          component={SignInView}
          exact
          layout={MinimalLayout}
          path={ROUTES.SIGN_IN}
        />
        <RouteWithLayout
          component={WaitlistView}
          exact
          layout={MinimalLayout}
          path={ROUTES.WAITLIST}
        />
        <RouteWithLayout
          component={ResetPasswordView}
          exact
          layout={MinimalLayout}
          path={ROUTES.RESET_PASSWORD}
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path={ROUTES.NOT_FOUND}
        />
        <Redirect to={ROUTES.NOT_FOUND} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
