import * as ActionTypes from 'actions/ActionTypes';


const INITIAL_STATE = {
  currentUser: null,
  error: false,
  fetching: false,
  token: localStorage.getItem('token'),
};


const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case ActionTypes.SIGN_UP_START:
    case ActionTypes.SIGN_IN_START: {
      return { 
        ...state, 
        error: false, 
        fetching: true 
      };
    }

    case ActionTypes.SIGN_IN_SUCCESS: {
      return {
        ...state,
        currentUser: action.payload.user,
        error: false,
        fetching: false,
        token: action.payload.token,
      };
    }

    case ActionTypes.SIGN_UP_FAILURE:
    case ActionTypes.SIGN_IN_FAILURE: {
      return {
        ...state,
        fetching: false,
        error: action.payload,
        currentUser: null,
        token: null,
      };
    }

    case ActionTypes.SIGN_OUT: {
      return {
        ...state,
        currentUser: null,
        token: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default userReducer;
