import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import {
  ArtistContent,
  ArtistContentMobile,
  WaitingDialog,
  SnackAlert,
} from "components";
import { TAB_ARTIST } from "constants/types";
// import { getGenres } from "services/genreServices";
import { getArtistById, getArtistBySlug } from "services/artistServices";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 180,
    // [theme.breakpoints.up("sm")]: {
    //   top: "180px",
    // },
    margin: theme.spacing(2),
  },
  mobilecontainer: {
    position: "relative",
    paddingTop: 180,
  },
});

class Artist extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickArtist = this.handleClickArtist.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
  }

  componentDidMount = async () => {
    window.scrollTo(0, 0);

    const { 
      // genres, 
      selected_artist, 
      match 
    } = this.props;
    // const genre_slug = match.params.genre_slug;
    const artist_slug = match.params.artist_slug;


    // // Get Genres
    // if (genres.length === 0) {
    //   try {
    //     const genres = await getGenres();

    //     if (genre_slug !== undefined) {
    //       const genre2select = genres.find(
    //         (genre) => genre.slug === genre_slug
    //       );
    //       if (genre2select !== undefined) {
    //         this.props.selectGenre(genre2select);
    //       }
    //     }

    //     this.props.setGenres(genres);
    //   } catch (err) {
    //     this.setError(err.message);
    //   }
    // }

    // Get Artist
    if (selected_artist === null) {
      try {
        const artist = await getArtistBySlug(artist_slug);
          this.props.selectArtist(artist);
          console.log(artist)
      } catch (err) {
        this.setError(err.message);
      }
    }
  };

  setError = (message) => {
    this.props.setError(message);
    this.props.requestDataFinished();
  };

  handleCloseError = () => {
    this.props.clearError();
  };

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  };

  handleClickArtist = async (artist_id) => {
    console.log("click artist :", artist_id);
    // const { selected_genre } = this.props;

    // this.setWaiting(true);
    const artists = await getArtistById(artist_id);
    if (artists.length > 0) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.props.selectArtist(artists[0]);

      let route = `/artist/${artists[0].slug}`;
      // if (selected_genre !== null) {
      //   route = `/genres/${selected_genre.slug}/artist/${artists[0].slug}`;
      // }
      const location = {
        pathname: route,
      };
      this.props.history.push(location);

      this.props.setCurrentTab(TAB_ARTIST);
      // this.setWaiting(false);
    } else {
      this.setError("Can't find the artist");
    }
  };

  render() {
    const {
      classes,
      selected_artist,
      requesting,
      error,
      errorMsg,
      // genres,
      // selected_genre,
    } = this.props;

    // const genre_slug = this.props.match.params.genre_slug;
    // if (
    //   genres.length === 0 ||
    //   (genre_slug !== undefined && selected_genre === null)
    // ) {
    //   return <div></div>;
    // }
    if (selected_artist === null) {
      return <div></div>;
    }

    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <div className={classes.maincontainer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item xs={2} />

              <Grid item xs={8}>
                <ArtistContent
                  artist={selected_artist}
                  onClickArtist={this.handleClickArtist}
                />
              </Grid>

              <Grid item xs={2} />
            </Grid>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.mobilecontainer}>
            <ArtistContentMobile
              artist={selected_artist}
              onClickArtist={this.handleClickArtist}
            />
          </div>
        </Hidden>
        <WaitingDialog open={requesting} />
        {error && (
          <SnackAlert
            variant={"error"}
            message={errorMsg}
            openStats={error}
            vertical={"top"}
            horizontal={"center"}
            handleEvent={this.handleCloseError}
          />
        )}
      </div>
    );
  }
}

Artist.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  requesting: state.uiState.requesting,
  error: state.uiState.error,
  errorMsg: state.uiState.errorMsg,
  // genres: state.dataState.genres,
  // selected_genre: state.dataState.selected_genre,
  selected_artist: state.dataState.selected_artist,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Artist);
