import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
  },
  avatar_container: {
    margin: theme.spacing(2),
  },
  avatar: {
    width: 160,
    height: 160,
    borderRadius: 80,
  },
  avatar_mobile: {
    width: 112,
    height: 112,
    borderRadius: 56,
  },
  name_container: {
    textAlign: "center",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  content: {
    width: 160,
  },
  content_mobile: {
    width: 112,
  }
});

class ArtistCard extends React.Component {

  render() {
    const { classes, artist, isMobile, onClickArtist } = this.props;

    const name = artist.profile.name;
    let avatar = "";
    try {
      avatar = artist.visuals.avatarImage.sources[0].url;
    } catch (e) {
      avatar = "/static/images/blank_avatar.png";
    }

    return (
      <Card className={classes.root}>
        <CardActionArea onClick={e => onClickArtist(artist.id)}>
          <div className={classes.avatar_container}>
            <img 
              className={isMobile ? classes.avatar_mobile : classes.avatar} 
              src={avatar} 
              alt={artist.name} 
            />
          </div>
          <CardContent>
            <div className={classes.name_container}>
              <Typography className={isMobile ? classes.content_mobile : classes.content} gutterBottom variant="h5" component="h5">
                {name}
              </Typography>
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

ArtistCard.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(ArtistCard);
