/* eslint-disable linebreak-style */
import React from "react";
import ReactDOM from "react-dom";
import { Provider as StoreProvider } from "react-redux";

import * as serviceWorker from "./serviceWorker";

import { configureStore } from "./store";
import App from "./App";
import ThemeWrapper from "./ThemeWrapper";

const store = configureStore();

ReactDOM.render(
  <StoreProvider store={store}>
    <ThemeWrapper>
      <App />
    </ThemeWrapper>
  </StoreProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
