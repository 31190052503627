// signin method
export const SIGN_METHOD_EMAIL          = 0;
export const SIGN_METHOD_GOOGLE         = 1;
export const SIGN_METHOD_APPLE          = 2;
export const SIGN_METHOD_PHONE          = 3;

// theme mode
export const THEME_MODE_LIGHT           = 'light';
export const THEME_MODE_DARK            = 'dark';

export const TAB_RANKINGS               = 0;
// export const TAB_GENRES                 = 1;
export const TAB_TOPSONGS               = 1;
export const TAB_TOPEARNERS             = 2;
export const TAB_ARTIST                 = 3; // not exist
export const TAB_SEARCHRESULT           = 4; // not exist

export const AUTH_NOT_APPROVED          = 401;
export const GRAPHQL_SUCCESS            = 0;
export const GRAPHQL_ERROR              = -1;
export const PAGE_SIZE                  = 20;

// width of card & view
export const MAX_WINDOW_WIDTH           = 1920;     // breakpoint lg
export const MIN_TABLET_WIDTH           = 844;
export const MIN_CARD_WIDTH             = 320;
export const MAX_CARD_WIDTH             = 414;
export const MAX_ARTICLE_WIDTH          = 640;

// user types
export const ROLE_ANONYMOUS_USER        = 0;
export const ROLE_FREE_USER             = 1;
export const ROLE_PREMIUM_USER          = 2;

// local debug
export const LOCAL_DEBUG                = false;

// Privacy
export const PRIVACY_POLICY             = 0;
export const COOKIE_POLICY              = 1;
export const TERMS_CONDITIONS           = 2;

// Metric of Earnings
export const METRIC_EARNINGS            = 0.004; // 0.004 * 3;