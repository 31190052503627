import axios from 'axios';
import { LOCAL_DEBUG } from 'constants/types';

let SERVER_URL = "https://musicfuturesapi.hoyack.com";
// let SERVER_URL = "http://localhost:3001";
if (LOCAL_DEBUG) {
  SERVER_URL = "http://localhost:5000";
}

export const getGenres = async () => {
  try {
    const url = `${SERVER_URL}/api/v1/genre`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};


export const getGenreArtistCount = async (genre_id) => {
  try {
    const url = `${SERVER_URL}/api/v1/genre/${genre_id}/artist/count`;
    const response = await axios.get(url);
    return response.data.count;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
}


export const getGenreArtists = async (genre_id, filter = "", pagesize = 20, offset = 0) => {
  try {
    const url = `${SERVER_URL}/api/v1/genre/${genre_id}/artist/?filter=${filter}&pagesize=${pagesize}&offset=${offset}`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw new Error(err.response.data.error);
  }
};

// /**
//  * Either likes or dislikes a post
//  * @function votePost
//  * @param {string} postId The id of the post to be voted on
//  * @param {*} authToken The user's auth token
//  */
// export const votePost = async (postId, authToken) => {
//   try {
//     await axios.post(`${SERVER_URL}/api/post/${postId}/vote`, null, {
//       headers: { authorization: authToken },
//     });
//   } catch (err) {
//     throw new Error(err.response.data.error);
//   }
// };

