import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';


const styles = theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  iconbutton: {
    color: theme.palette.text.primary,
    "&:disabled" : { color: theme.palette.text.disabled },
  }
});


class TablePaginationActions extends React.Component {
  render() {
    const { classes, count, page, rowsPerPage, onPageChange } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          className={classes.iconbutton}
          onClick={e => onPageChange(0)}
          disabled={page === 0}
          aria-label="first page"
        >
          <FirstPageIcon />
        </IconButton>
        <IconButton
          className={classes.iconbutton}
          onClick={e=> onPageChange(page - 1)} 
          disabled={page === 0} 
          aria-label="previous page"
        >
          <KeyboardArrowLeft />
        </IconButton>
        <IconButton
          className={classes.iconbutton}
          onClick={e => onPageChange(page + 1)}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          <KeyboardArrowRight />
        </IconButton>
        <IconButton
          className={classes.iconbutton}
          onClick={e => onPageChange(Math.max(0, Math.ceil(count / rowsPerPage) - 1))}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          <LastPageIcon />
        </IconButton>
      </div>
    );
  }
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TablePaginationActions);