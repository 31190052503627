import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import { Topbar } from './components';
import { MAX_WINDOW_WIDTH } from 'constants/types';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: `calc(100vh)`,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.main,
    // borderLeft: '1px solid #C0C0C0',
    // borderRight: '1px solid #C0C0C0',
  },
  content: {
    backgroundColor: theme.palette.background.default
  }
}));

const Policy = props => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};

Policy.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Policy;
