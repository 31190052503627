import * as ActionTypes from './ActionTypes';

import { disconnectSocket } from './socketActions';


export const signOut = () => (dispatch) => {
  localStorage.removeItem('token');
  dispatch(disconnectSocket());
  dispatch({ type: ActionTypes.SIGN_OUT });
}

export const signInSuccess = (user) => {
  localStorage.setItem('token', user.token);
  return {
    type: ActionTypes.SIGN_IN_SUCCESS,
    payload: user,
  };
}

export const signInStart = () => (dispatch) => {
  dispatch({
    type: ActionTypes.SIGN_IN_START 
  });
}

export const signInFailure = (err) => ({
  type: ActionTypes.SIGN_IN_FAILURE,
  payload: err,
})

export const signUpStart = () => (dispatch) => {
  dispatch({
    type: ActionTypes.SIGN_UP_START 
  });
}

export const signUpFailure = (err) => ({
  type: ActionTypes.SIGN_UP_FAILURE,
  payload: err,
})