import axios from 'axios';
import { LOCAL_DEBUG } from 'constants/types';

let SERVER_URL = "https://musicfuturesapi.hoyack.com";
// let SERVER_URL = "http://localhost:3001"
if (LOCAL_DEBUG) {
  SERVER_URL = "http://localhost:5000";
}

export const addToWaitlist = async (name, email) => {
    const body = {name, email};
    const url = `${SERVER_URL}/api/v1/waitlist/send-to-waitlist`;
    try {
        await axios.post(url, body);
        return;
    } catch (e) {
        console.error("Error submitting form...", e);
    }
}