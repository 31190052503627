import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Input } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
  root: {
    border: '1px solid #027BBD',
    borderRadius: '24px',
    alignItems: 'center',
    display: 'flex',
    flexBasis: 640,
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      flexBasis: 320,
      padding: 2,
    }
    // backgroundColor: '#36373F'
  },
  icon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: "#3579b8"
  },
  input: {
    flexGrow: 1,
    borderRadius: '16px',    
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.05px',
    color: '#3579b8'
  }
}));

const SearchInput = props => {
  const { className, style, onChange, onKeyPress } = props;

  const classes = useStyles();

  return (
    <Paper
      className={clsx(classes.root, className)}
      style={style}
    >
      <SearchIcon className={classes.icon} />
      <Input
        className={classes.input}
        disableUnderline
        onChange={onChange}
        onKeyPress={onKeyPress}
        placeholder='Enter artist name'
      />
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func
};

export default SearchInput;
