import * as ActionTypes from "./ActionTypes";

export const setGenres = (genres) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_GENRES,
    genres,
  });
};

export const selectGenre = (genre) => (dispatch) => {
  dispatch({
    type: ActionTypes.SELECT_GENRE,
    genre,
  });
};

export const setArtistCount = (count) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ARTIST_COUNT,
    count,
  });
};

export const setCurrentTab = (tabValue) => dispatch => {
  dispatch({
    type: ActionTypes.SET_CURRENT_TAB,
    tabValue
  });
}

export const setArtists = (artists) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ARTISTS,
    artists: artists
  });
};

export const selectArtist = (artist) => (dispatch) => {
  dispatch({
    type: ActionTypes.SELECT_ARTIST,
    artist: artist,
  });
};

export const setArtistsList = (artists) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_ARTISTS_LIST,
    artists: artists
  });
};

export const appendArtistsList = (artists) => (dispatch) => {
  dispatch({
    type: ActionTypes.APPEND_ARTISTS_LIST,
    artists: artists
  });
};

export const setSearchKey = (searchkey) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SEARCHKEY,
    searchkey: searchkey
  });
};

export const setSearchResult = (artists) => (dispatch) => {
  dispatch({
    type: ActionTypes.SET_SEARCHRESULT,
    artists: artists
  });
};