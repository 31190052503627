import React from 'react';
import { withRouter } from 'react-router-dom';
import { alpha, withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import {
  AppBar,
  Toolbar,
  Grid,
  // Typography,
  InputBase,
  Tabs,
  Tab,
  Hidden,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider
} from '@material-ui/core';
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import StarIcon from '@material-ui/icons/Star';
// import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import SearchIcon from '@material-ui/icons/Search';
import EnhancedEncryptionIcon from '@material-ui/icons/EnhancedEncryption';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ListAltIcon from '@material-ui/icons/ListAlt';
import {
  MAX_WINDOW_WIDTH,
  TAB_SEARCHRESULT,
  // TAB_GENRES,
  TAB_RANKINGS,
  TAB_TOPSONGS,
  TAB_TOPEARNERS,
  PRIVACY_POLICY,
  COOKIE_POLICY,
  TERMS_CONDITIONS
} from 'constants/types';
import { getArtistsByName } from "services/artistServices";
import * as ROUTES from "constants/routes";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    position: "fixed",
    zIndex: 1100,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    left: 0,
    top: 0,
    backgroundColor: theme.palette.background.main,
    // borderLeft: '1px solid #C0C0C0',
    // borderRight: '1px solid #C0C0C0',
    // borderBottom: `2px solid ${theme.palette.text.secondary}`
  },
  toolbox: {
    display: 'flex'
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    cursor: 'pointer',
  },
  image: {
    // objectFit: "cover",
    height: 115,
    width: 128,
    borderRadius: 2,
    backgroundColor: theme.palette.background.main
  },
  // genre_title: {
  //   fontSize: 20,
  //   fontWeight: 500,
  //   color: "#255a8e",
  //   fontFamily: 'Montserrat'
  // },
  logo_mobile: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    cursor: 'pointer',
    position: 'relative',
  },
  search: {
    position: 'relative',
    border: '1px solid black',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '16ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
  tabs: {
    backgroundColor: "#255a8e",
    height: "60px",
    marginLeft: -1,
    marginRight: -1,
    color: "#FFFFFF"
  },
  tab: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontSize: 16,
    textTransform: 'none',
  },
  hidden: {
    width: "100%",
  },
  drawerPaper: {
    border: "none",
    bottom: "0",
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    width: 250,
    boxShadow: "0 1px 4px 0 rgba(0,0,0,0.14)",
    position: "fixed",
    display: "block",
    top: "0",
    height: "100vh",
    right: "0",
    left: "auto",
    visibility: "visible",
    overflowY: "visible",
    borderTop: "none",
    textAlign: "left",
    paddingRight: "0px",
    paddingLeft: "0",
    transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
  },
  closeButtonDrawer: {
    position: "absolute",
    right: "8px",
    top: "9px",
    zIndex: "1",
  },
  appResponsive: {
    margin: "20px 10px",
    marginTop: "40px",
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

class Topbar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchTxt: "",
      mobileOpen: false
    };

    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.handleClickLogo = this.handleClickLogo.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.handleClickMenu = this.handleClickMenu.bind(this);
    this.handleClickPolicy = this.handleClickPolicy.bind(this);
  }

  componentDidMount() {
    this.setState({
      searchTxt: this.props.searchKey
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchKey !== prevProps.searchKey) {
      // console.log("this props :", this.props);
      // console.log("prevProps :", prevProps);
      this.setState({
        searchTxt: this.props.searchKey
      });
    }
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  setError = (message) => {
    this.props.setError(message);
    this.props.requestDataFinished();
  }

  handleDrawerToggle = () => {
    this.setState({
      ...this.state,
      mobileOpen: !this.state.mobileOpen
    });
  };

  handleChange = event => {
    this.setState({
      ...this.state,
      searchTxt: event.target.value
    });
  };

  handleSearch = event => {
    if (event.key === "Enter") {
      const searchkey = this.state.searchTxt.trim();
      if (searchkey.length > 0) {
        this.props.setSearchKey(searchkey);
        this.doSearch(searchkey);
      }
    }
  };

  doSearch = async (searchkey) => {
    this.setWaiting(true);

    // Get Artists
    try {
      const artists = await getArtistsByName(searchkey);
      this.props.setSearchResult(artists);
      this.props.setPageNo(0);
      this.props.setCurrentTab(TAB_SEARCHRESULT);

      this.props.history.push(ROUTES.SEARCHRESULTS);
    } catch (err) {
      this.setError(err.message);
    }

    this.setWaiting(false);
  }

  handleSignOut = async () => {
    await this.props.signOut();

    this.initialize();
  }

  handleLogin = () => {

    this.props.history.push(ROUTES.SIGN_IN);
    this.props.setLoginBackRoute(this.props.location.pathname);
  }

  handleClickLogo = () => {
    // this.props.selectGenre(null);
    this.props.setSearchKey("");
    this.props.setArtists([]);
    this.props.setArtistsList([]);
    this.props.setPageNo(0);

    this.props.history.push(ROUTES.HOME);
  }

  handleChangeTab = (event, tabValue) => {
    const { currentTab } = this.props;
    if (currentTab === tabValue) {
      return;
    }

    console.log("tab changed :", tabValue);
    this.props.setSearchKey("");
    this.props.setArtists([]);
    this.props.setArtistsList([]);
    this.props.setPageNo(0);

    // const { selected_genre } = this.props;

    let route = ROUTES.HOME;
    // if (selected_genre === null) {
    // if (tabValue === TAB_GENRES) {
    //   route = ROUTES.GENRES;
    // } 
    if (tabValue === TAB_RANKINGS) {
      route = ROUTES.RANKINGS;
    } else if (tabValue === TAB_TOPSONGS) {
      route = ROUTES.TOPSONGS;
    } else if (tabValue === TAB_TOPEARNERS) {
      route = ROUTES.TOPEARNERS;
    }
    // } else {
    //   if (tabValue === TAB_GENRES) {
    //     route = ROUTES.GENRES;
    //   } else if (tabValue === TAB_RANKINGS) {
    //     route = `/genres/${selected_genre.slug}/rankings`;
    //   } else if (tabValue === TAB_TOPSONGS) {
    //     route = `/genres/${selected_genre.slug}/topsongs`;
    //   } else if (tabValue === TAB_TOPEARNERS) {
    //     route = `/genres/${selected_genre.slug}/topearners`;
    //   }
    // }

    this.props.history.push(route);
    this.props.setCurrentTab(tabValue);
  }

  handleClickMenu = (tabValue) => {
    const { currentTab } = this.props;
    if (currentTab === tabValue) {
      return;
    }

    console.log("tab changed :", tabValue);
    this.props.setSearchKey("");
    this.props.setArtists([]);
    this.props.setArtistsList([]);
    this.props.setPageNo(0);

    // const { selected_genre } = this.props;

    let route = ROUTES.HOME;
    // if (selected_genre === null) {
    // if (tabValue === TAB_GENRES) {
    //   route = ROUTES.GENRES;
    // } 
    if (tabValue === TAB_RANKINGS) {
      route = ROUTES.RANKINGS;
    } else if (tabValue === TAB_TOPSONGS) {
      route = ROUTES.TOPSONGS;
    } else if (tabValue === TAB_TOPEARNERS) {
      route = ROUTES.TOPEARNERS;
    }
    // } else {
    //   if (tabValue === TAB_GENRES) {
    //     route = ROUTES.GENRES;
    //   } else if (tabValue === TAB_RANKINGS) {
    //     route = `/genres/${selected_genre.slug}/rankings`;
    //   } else if (tabValue === TAB_TOPSONGS) {
    //     route = `/genres/${selected_genre.slug}/topsongs`;
    //   } else if (tabValue === TAB_TOPEARNERS) {
    //     route = `/genres/${selected_genre.slug}/topearners`;
    //   }
    // }

    this.handleDrawerToggle();
    this.props.history.push(route);
    this.props.setCurrentTab(tabValue);
  }

  handleClickPolicy = (policy) => {
    console.log("policy :", policy);

    if (policy === PRIVACY_POLICY) {
      this.props.history.push(ROUTES.PRIVACY_POLICY);
    } else if (policy === COOKIE_POLICY) {
      this.props.history.push(ROUTES.COOKIE_POLICY);
    } else if (policy === TERMS_CONDITIONS) {
      this.props.history.push(ROUTES.TERMS_CONDITIONS);
    }

    this.createPolicy(document, 'script', 'termly-jssdk');
  }

  createPolicy = (d, s, id) => {
    var element = d.getElementById(id);
    if (element) {
      element.parentNode.removeChild(element);
    };

    var js, tjs = d.getElementsByTagName(s)[0];

    js = d.createElement(s);
    js.id = id;
    js.src = "https://app.termly.io/embed-policy.min.js";

    tjs.parentNode.insertBefore(js, tjs);
  }

  render() {
    const {
      classes,
      currentTab,
      // selected_genre
    } = this.props;
    const {
      searchTxt,
      mobileOpen
    } = this.state;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Hidden smDown implementation="css">
                  <div className={classes.logo} onClick={this.handleClickLogo}>
                    <img className={classes.image} src={"/static/images/logo/music-futures-logo-with-name.png"} alt={"logo"} />
                  </div>
                </Hidden>
                <Hidden mdUp implementation="css">
                  <div className={classes.logo_mobile} onClick={this.handleClickLogo}>
                    <img className={classes.image} src={"/static/images/logo/music-futures-logo-with-name.png"} alt={"logo"} />
                  </div>
                </Hidden>
              </Grid>
              {/* {selected_genre &&
                <Hidden smDown implementation="css">
                  <Grid item>
                    <Typography className={classes.genre_title}>
                      {selected_genre.name}
                    </Typography>
                  </Grid>
                </Hidden>
              }
              {selected_genre &&
                <Hidden mdUp implementation="css">
                  <Grid item>
                    <Typography className={classes.genre_title}>
                      {selected_genre.name}
                    </Typography>
                  </Grid>
                </Hidden>
              } */}
              <Hidden smDown implementation="css">
                <Grid item>
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon />
                    </div>
                    <InputBase
                      placeholder="Enter artist name"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchTxt}
                      onChange={this.handleChange}
                      onKeyPress={this.handleSearch}
                    />
                  </div>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={this.handleDrawerToggle}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Hidden>
            </Grid>
          </Toolbar>
          <Hidden smDown implementation="css" className={classes.hidden}>
            <Tabs
              className={classes.tabs}
              value={currentTab}
              onChange={this.handleChangeTab}
              aria-label="main_tab"
              centered
            >
              <Tab
                className={classes.tab}
                label="Rankings"
              />
              {/* <Tab
                className={classes.tab}
                label="Genres"
              /> */}
              <Tab
                className={classes.tab}
                label="Top Songs"
              />
              <Tab
                className={classes.tab}
                label="Top Earners"
              />
            </Tabs>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Enter artist name"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchTxt}
                onChange={this.handleChange}
                onKeyPress={this.handleSearch}
              />
            </div>
          </Hidden>
          <Hidden mdUp implementation="js">
            <Drawer
              variant="temporary"
              anchor={"right"}
              open={mobileOpen}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={this.handleDrawerToggle}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={this.handleDrawerToggle}
                className={classes.closeButtonDrawer}
              >
                <CloseIcon />
              </IconButton>
              <div className={classes.appResponsive}>
                <List>
                  <ListItem
                    button
                    key={"Rankings"}
                    selected={currentTab === TAB_RANKINGS}
                    onClick={e => this.handleClickMenu(TAB_RANKINGS)}
                  >
                    <ListItemIcon><StarIcon /></ListItemIcon>
                    <ListItemText primary={"Rankings"} />
                  </ListItem>
                  {/* <ListItem
                    button
                    key={"Genres"}
                    selected={currentTab === TAB_GENRES}
                    onClick={e => this.handleClickMenu(TAB_GENRES)}
                  >
                    <ListItemIcon><VisibilityIcon /></ListItemIcon>
                    <ListItemText primary={"Genres"} />
                  </ListItem> */}
                  <ListItem
                    button
                    key={"Top Songs"}
                    selected={currentTab === TAB_TOPSONGS}
                    onClick={e => this.handleClickMenu(TAB_TOPSONGS)}
                  >
                    <ListItemIcon><FavoriteIcon /></ListItemIcon>
                    <ListItemText primary={"Top Songs"} />
                  </ListItem>
                  <ListItem
                    button
                    key={"Top Earners"}
                    selected={currentTab === TAB_TOPEARNERS}
                    onClick={e => this.handleClickMenu(TAB_TOPEARNERS)}
                  >
                    <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
                    <ListItemText primary={"Top Earners"} />
                  </ListItem>
                  <Divider className={classes.divider} />
                  <ListItem
                    button
                    key={"Privacy Policy"}
                    selected={false}
                    onClick={e => this.handleClickPolicy(PRIVACY_POLICY)}
                  >
                    <ListItemIcon><EnhancedEncryptionIcon /></ListItemIcon>
                    <ListItemText primary={"Privacy Policy"} />
                  </ListItem>
                  <ListItem
                    button
                    key={"Cookie Policy"}
                    selected={false}
                    onClick={e => this.handleClickPolicy(COOKIE_POLICY)}
                  >
                    <ListItemIcon><FingerprintIcon /></ListItemIcon>
                    <ListItemText primary={"Cookie Policy"} />
                  </ListItem>
                  <ListItem
                    button
                    key={"Terms and Conditions"}
                    selected={false}
                    onClick={e => this.handleClickPolicy(TERMS_CONDITIONS)}
                  >
                    <ListItemIcon><ListAltIcon /></ListItemIcon>
                    <ListItemText primary={"Terms and Conditions"} />
                  </ListItem>
                </List>
              </div>
            </Drawer>
          </Hidden>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentTab: state.dataState.currentTab,
  searchKey: state.dataState.searchKey,
  // selected_genre: state.dataState.selected_genre
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Topbar);
