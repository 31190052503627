import { createTheme } from '@material-ui/core/styles';
import palette from './palette';
import typography from './typography';

const theme = createTheme({
  palette,
  typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1920,
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: '0 0 rgba(63,63,68,0.05), 0 0 rgba(63,63,68,0.15)'
      },
      elevation4: {
        boxShadow: '0 0 rgba(63,63,68,0.05), 0 0 rgba(63,63,68,0.15)'
      },      
    },
    MuiTab: {
      wrapper: {
        flexDirection:'row',
      },
      labelIcon: {
        minHeight: 16,
      },
    },
    MuiCardContent: {
      root: {
        padding: 0,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
