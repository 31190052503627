import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Grid, Hidden } from '@material-ui/core';
import { MAX_WINDOW_WIDTH } from 'constants/types';
import * as ROUTES from "constants/routes";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    position: "fixed",
    zIndex: 1100,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    left: 0,
    top: 0,
    backgroundColor: theme.palette.background.main,
    // borderLeft: '1px solid #C0C0C0',
    // borderRight: '1px solid #C0C0C0',
    // borderBottom: `2px solid ${theme.palette.text.secondary}`
  },
  toolbox: {
    display: 'flex'
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    cursor: 'pointer',
  },
  image: {
    height: 115,
    width: 124,
    borderRadius: 2,
    backgroundColor: theme.palette.background.main
  },
  logo_mobile: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'left',
    cursor: 'pointer',
    position: 'relative',
  },
});

class Topbar extends React.Component {

  constructor(props) {
    super(props);

    this.handleClickLogo = this.handleClickLogo.bind(this);
  }

  handleClickLogo = () => {
    this.props.selectGenre(null);
    this.props.setSearchKey("");
    this.props.setArtists([]);
    this.props.setArtistsList([]);
    this.props.setPageNo(0);

    this.props.history.push(ROUTES.HOME);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Hidden smDown implementation="css">
                  <div className={classes.logo} onClick={this.handleClickLogo}>
                    <img className={classes.image} src={"/static/images/logo/music-futures-logo-with-name.png"} alt={"logo"} />
                  </div>
                </Hidden>
                <Hidden mdUp implementation="css">
                  <div className={classes.logo_mobile} onClick={this.handleClickLogo}>
                    <img className={classes.image} src={"/static/images/logo/music-futures-logo-with-name.png"} alt={"logo"} />
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
};

Topbar.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(Topbar);

