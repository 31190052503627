import * as UserActions from './userActions';
import * as SocketActions from './socketActions';
import * as UIActions from './uiActions';
import * as DataActions from './dataActions';

export const ActionCreators = {
    ...UserActions,
    ...SocketActions,
    ...UIActions,
    ...DataActions
};