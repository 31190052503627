import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Hidden } from "@material-ui/core";
import {
  ArtistTopEarnerTable,
  ArtistList,
  WaitingDialog,
  SnackAlert 
} from "components";
import { TAB_TOPEARNERS } from "constants/types";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 180,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 180,
    },
  },
  item: {
    margin: theme.spacing(2),
  },
  mobileitem: {
    margin: theme.spacing(1),
  }
});

class TopEarners extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickArtist = this.handleClickArtist.bind(this);
    this.handleCloseError = this.handleCloseError.bind(this);
  }

  componentDidMount = async () => {
    window.scrollTo(0,0);
    this.props.setCurrentTab(TAB_TOPEARNERS);
  }

  setError = (message) => {
    this.props.setError(message);
    this.props.requestDataFinished();
  }

  handleCloseError = () => {
    this.props.clearError();
  }

  handleClickArtist = (artist_slug) => {
    let route = `/artist/${artist_slug}`;
    this.props.history.push(route);
  }
  
  render() {
    const { 
      classes, 
      requesting, 
      error, 
      errorMsg, 
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.maincontainer}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
          >
            <Hidden smDown>
              <Grid item className={classes.item}>
                <ArtistTopEarnerTable
                  onClickArtist={this.handleClickArtist}
                />
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <Grid item className={classes.mobileitem}>
                <ArtistList
                  filter={"earning"}
                  onClickArtist={this.handleClickArtist}
                />
              </Grid>
            </Hidden>
          </Grid>
        </div>
        <WaitingDialog open={requesting} />
        {error && (
          <SnackAlert
            variant={"error"}
            message={errorMsg}
            openStats={error}
            vertical={"top"}
            horizontal={"center"}
            handleEvent={this.handleCloseError}
          />
        )}
      </div>
    );
  }
}

TopEarners.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  requesting: state.uiState.requesting,
  error: state.uiState.error,
  errorMsg: state.uiState.errorMsg
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(TopEarners);
