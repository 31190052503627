import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Container, Hidden } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
  },
  maincontainer: {
    position: "relative",
    paddingTop: 120,
    // [theme.breakpoints.up("sm")]: {
    //   top: "180px",
    // },
    margin: theme.spacing(2),
  },
  mobilecontainer: {
    position: "relative",
    paddingTop: 120,
  },
});

class PrivacyPolicy extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Hidden smDown implementation="css">
          <div className={classes.maincontainer}>
            <Container style={{ marginTop: "2rem" }}>
              <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>

              <p style={{ lineHeight: "2rem" }}>
                <b>1. Music Futures Privacy Commitment</b>
                <br></br>
                This is our new privacy policy that covers every MusicFutures, website that links here, and all of the products and services contained on those websites.</p>
              <ul style={{ marginLeft: "4rem" }}>
                <li>MusicFutures has never sold your information and we never will.</li>
                <li>We use your information to carry out requests you make of our business.</li>
                <li>We use your information to make our business better able to serve you.</li>
                <li>We only disclose personal information externally to comply with official law.</li>
                <li>For details, please read further below.</li>
              </ul>
              <br></br>
              <p style={{ lineHeight: "2rem" }}><b>1.1 General</b>
                <br></br>
                There are some limitations to the privacy we can promise you. We will disclose personal information if it's necessary to comply with a legal obligation, prevent fraud, enforce an agreement, or protect our users' safety. We do not currently honor Do Not Track signals from internet browsers; when a universal standard for processing them emerges, we will follow it. Third-party websites and social media widgets have their own separate privacy policies. Always check the relevant privacy policy before sharing personal information with third parties. You can always contact us to: ask questions about our privacy practices, request a GDPR-compliant Data Processing Addendum, alert us if you believe we have collected personal data from a minor, or ask to have your personal information removed from our blogs or forums. We will contact you to let you know if we make any major changes to our privacy policy, or in the highly unlikely event that we ever decide to sell our business.
                <br></br>
                <br></br>
                This Privacy Policy is effective from 1st January 2024</p>
            </Container>

          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.mobilecontainer}>
            <Container style={{ marginTop: "2rem" }}>
              <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>

              <p style={{ lineHeight: "2rem" }}>
                <b>1. Music Futures Privacy Commitment</b>
                <br></br>
                This is our new privacy policy that covers every MusicFutures, website that links here, and all of the products and services contained on those websites.</p>
              <ul style={{ marginLeft: "4rem" }}>
                <li>MusicFutures has never sold your information and we never will.</li>
                <li>We use your information to carry out requests you make of our business.</li>
                <li>We use your information to make our business better able to serve you.</li>
                <li>We only disclose personal information externally to comply with official law.</li>
                <li>For details, please read further below.</li>
              </ul>
              <br></br>
              <p style={{ lineHeight: "2rem" }}><b>1.1 General</b>
                <br></br>
                There are some limitations to the privacy we can promise you. We will disclose personal information if it's necessary to comply with a legal obligation, prevent fraud, enforce an agreement, or protect our users' safety. We do not currently honor Do Not Track signals from internet browsers; when a universal standard for processing them emerges, we will follow it. Third-party websites and social media widgets have their own separate privacy policies. Always check the relevant privacy policy before sharing personal information with third parties. You can always contact us to: ask questions about our privacy practices, request a GDPR-compliant Data Processing Addendum, alert us if you believe we have collected personal data from a minor, or ask to have your personal information removed from our blogs or forums. We will contact you to let you know if we make any major changes to our privacy policy, or in the highly unlikely event that we ever decide to sell our business.
                <br></br>
                <br></br>
                This Privacy Policy is effective from 1st January 2024</p>
            </Container>
          </div>
        </Hidden>
      </div>
    );
  }
}

PrivacyPolicy.propTypes = {
  className: PropTypes.string,
};

export default withStyles(styles)(PrivacyPolicy);
