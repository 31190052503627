import React from 'react';
import { withRouter } from 'react-router-dom';
import { alpha, withStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { 
  AppBar, 
  Toolbar, 
  Grid, 
  InputBase, 
  Tabs, 
  Tab 
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import * as ROUTES from "constants/routes";
import { 
  MAX_WINDOW_WIDTH,
  TAB_SEARCHRESULT,
  // TAB_GENRES,
  TAB_RANKINGS,
  TAB_TOPSONGS,
  TAB_TOPEARNERS
} from 'constants/types';
import { getArtistsByName } from "services/artistServices";

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  appbar: {
    position: "fixed",
    zIndex: 1100,
    width: MAX_WINDOW_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    left: 0,
    top: 0,
    backgroundColor: theme.palette.background.main,
    borderLeft: '1px solid #C0C0C0',
    borderRight: '1px solid #C0C0C0',
    // borderBottom: `2px solid ${theme.palette.text.secondary}`
  },
  toolbox: {
    display: 'flex'
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    cursor: 'pointer',
  },
  image: {
    // objectFit: "cover",
    height: 64,
    width: 64,
    borderRadius: 2,
    backgroundColor: theme.palette.background.main
  },
  search: {
    position: 'relative',
    border: '1px solid black',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 1),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '20ch',
      '&:focus': {
        width: '25ch',
      },
    },
  },
  tabs: {
    backgroundColor: "#255a8e", 
    height: "60px",
    marginLeft: -1,
    marginRight: -1,
    color: "#FFF"
  },
  tab: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    fontSize: 16,
    textTransform: 'none',
  }
});

class MainAppBar extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchTxt: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);

    this.handleClickLogo = this.handleClickLogo.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  componentDidMount() {
    this.setState({
      searchTxt: this.props.searchKey
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchKey !== prevProps.searchKey) {
      // console.log("this props :", this.props);
      // console.log("prevProps :", prevProps);
      this.setState({
        searchTxt: this.props.searchKey
      });
    }
  }

  setWaiting = (waiting) => {
    if (waiting) {
      this.props.requestDataPending();
    } else {
      this.props.requestDataFinished();
    }
  }

  setError = (message) => {
    this.props.setError(message);
    this.props.requestDataFinished();
  }

  handleChange = event => {
    this.setState({
      ...this.state,
      searchTxt: event.target.value
    });
  };

  handleSearch = event => {
    if (event.key === "Enter") {
      const searchkey = this.state.searchTxt.trim();
      if (searchkey.length > 0) {
        this.props.setSearchKey(searchkey);
        this.doSearch(searchkey);
      }
    }
  };

  doSearch = async (searchkey) => {
    this.setWaiting(true);

    // Get Artists
    try {
      const artists = await getArtistsByName(searchkey);
      this.props.setSearchResult(artists);
      this.props.setPageNo(0);
      this.props.setCurrentTab(TAB_SEARCHRESULT);

      this.props.history.push(ROUTES.SEARCHRESULTS);
    } catch(err) {
      this.setError(err.message);
    }

    this.setWaiting(false);
  }

  handleSignOut = async () => {
    await this.props.signOut();

    this.initialize();
  }

  handleLogin = () => {

    this.props.history.push(ROUTES.SIGN_IN);
    this.props.setLoginBackRoute(this.props.location.pathname);
  }

  handleClickLogo = () => {
    // this.props.selectGenre(null);
    this.props.setSearchKey("");
    this.props.setArtists([]);
    this.props.setPageNo(0);

    this.props.history.push(ROUTES.RANKINGS);
    this.props.setCurrentTab(TAB_RANKINGS);
  }

  handleChangeTab = (event, tabValue) => {
    const { currentTab } = this.props;
    if (currentTab === tabValue) {
      return;
    }

    console.log("tab changed :", tabValue);
    this.props.setSearchKey("");
    this.props.setArtists([]);
    this.props.setPageNo(0);

    // const { selected_genre } = this.props;

    let route = ROUTES.HOME;
    // if (selected_genre === null) {
    // if (tabValue === TAB_GENRES) {
    //   route = ROUTES.GENRES;
    // }
    if (tabValue === TAB_RANKINGS) {
      route = ROUTES.RANKINGS;
    } else if (tabValue === TAB_TOPSONGS) {
      route = ROUTES.TOPSONGS;
    } else if (tabValue === TAB_TOPEARNERS) {
      route = ROUTES.TOPEARNERS;
    }
    // } else {
    //   if (tabValue === TAB_GENRES) {
    //     route = ROUTES.GENRES;
    //   } else if (tabValue === TAB_RANKINGS) {
    //     route = `/genres/${selected_genre.slug}/rankings`;
    //   } else if (tabValue === TAB_TOPSONGS) {
    //     route = `/genres/${selected_genre.slug}/topsongs`;
    //   } else if (tabValue === TAB_TOPEARNERS) {
    //     route = `/genres/${selected_genre.slug}/topearners`;
    //   }
    // }

    const location = {
      pathname: route
    };
    this.props.history.push(location);
    this.props.setCurrentTab(tabValue);
  }


  render() {
    const {
      classes,
      currentTab,
    } = this.props;
    const { searchTxt } = this.state;

    return (
      <div className={classes.root}>
        <AppBar className={classes.appbar}>
          <Toolbar>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <div className={classes.logo} onClick={this.handleClickLogo}>
                  <img className={classes.image} src={"/static/images/logo/music-futures-logo-with-name.png"} alt={"logo"} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Enter artist name"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchTxt}
                    onChange={this.handleChange}
                    onKeyPress={this.handleSearch}
                  />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
          <Tabs
            className={classes.tabs} 
            value={currentTab} 
            onChange={this.handleChangeTab} 
            aria-label="main_tab"
            centered
          >
            <Tab 
              className={classes.tab}
              label="Rankings" 
            />
            {/* <Tab 
              className={classes.tab}
              label="Genres" 
            /> */}
            <Tab 
              className={classes.tab}
              label="Top Songs" 
            />
            <Tab 
              className={classes.tab}
              label="Top Earners" 
            />
          </Tabs>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentTab: state.dataState.currentTab,
  searchKey: state.dataState.searchKey,
  // selected_genre: state.dataState.selected_genre
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(MainAppBar);
