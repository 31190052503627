import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem } from "@material-ui/core";
import { RankingCard } from "components";
import { TAB_ARTIST } from 'constants/types';


const styles = theme => ({
  root: {
    width: "100%",
    // padding: theme.spacing(1),
    // marginTop: theme.spacing(1),
    backgroundColor: theme.palette.background.main
  },
  listitem: {
    padding: 0
  }
});

class SearchResultList extends React.Component {
  constructor(props) {
    super(props);

    this.handleClickArtist = this.handleClickArtist.bind(this);
  }

  componentDidMount = async() => {
    window.scrollTo(0, 0);
  }

  handleClickArtist = artist => {
    this.props.saveScrollPos(window.scrollX, window.scrollY);

    this.props.selectArtist(artist);
    this.props.setCurrentTab(TAB_ARTIST);
    this.props.onClickArtist(artist.slug);
  };

  render() {
    const { 
      classes,
      searchResults, 
    } = this.props;

    if (searchResults.length === 0) {
      return <div></div>;
    }

    return (
      <div className={classes.root}>
        <List component="artist-list" aria-label="artist list">
          {searchResults.map(artist => (
            <ListItem className={classes.listitem} key={artist.id}>
              <RankingCard
                artist={artist}
                onClickArtist={this.handleClickArtist}
              />
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  searchResults: state.dataState.searchResults,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SearchResultList);
