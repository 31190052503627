import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ActionCreators } from "actions";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Box,
  TextField,
} from "@material-ui/core";
import validate from "validate.js";
import { BasicAppBar, SnackAlert, WaitingDialog } from "components";
import * as ROUTES from "constants/routes";
import { MAX_ARTICLE_WIDTH } from "constants/types";
import { login } from 'services/authenticationServices';


const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128
    }
  }
};


const styles = theme => ({
  root: {
    minHeight: `calc(100vh)`,
    width: MAX_ARTICLE_WIDTH,
    maxWidth: '100%',
    margin: '0 auto',
    backgroundColor: theme.palette.background.default,
  },
  appbar: {
    width: MAX_ARTICLE_WIDTH,
    maxWidth: "100%",
    height: "56px",
    [theme.breakpoints.up('sm')]: {
      height: "64px",
    },
  },
  container: {
    paddingLeft: 8,
    paddingRight: 8,
    backgroundColor: theme.palette.background.default
  },
  title: {
    flexGrow: 1,
    textAlign: "center"
  },
  slider: {
    marginBottom: 16
  },
  textField: {
    color: theme.palette.text.primary,
    marginTop: 8,
    marginBottom: 0
  },
  customInput: {
    "&:before": {
      borderBottomColor: theme.palette.text.secondary
    },
    "&:after": {
      borderBottomColor: theme.palette.text.secondary
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: theme.palette.text.secondary
    }
  },
  signupline: {
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginTop: 20,
    marginBottom: 4
  },
  resetline: {
    color: theme.palette.text.secondary,
    textAlign: "center",
    marginTop: 4,
    marginBottom: 10
  },
  submitBtn: {
    backgroundColor: "#1878F3",
    color: "#FFFFFF",
    borderRadius: "30px",
    padding: "10px 8px",
    width: "100%",
    textTransform: "initial",
    marginTop: 10,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#1878F3",
      color: "#FFFFFF",
    }
  },
  signinForm: {
    marginLeft: 16,
    marginRight: 16
  },
  socialIcons: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  socialIcon: {
    color: theme.palette.text.primary,
    Width: 36,
    height: 36,
    marginRight: theme.spacing(1),
  },
  emailIcon: {
    color: theme.palette.text.primary,
    width: 36,
    height: 36,
    marginRight: theme.spacing(1),
    padding: 4,
  },
  error: {
    color: theme.palette.error.main
  },
  info: {
    color: theme.palette.info.main
  },
  signlink: {
    color: theme.palette.text.primary,
    textDecoration: "none"
  }
});

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msgToken: null,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
      autherror: null,
      success: false,
      successMsg: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNavBack = this.handleNavBack.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleSkip = this.handleSkip.bind(this);

    this.handleCloseError = this.handleCloseError.bind(this);
  }

  componentDidMount = () => {
    // if (this.props.firebase.messaging !== null) {
    //   const messaging = this.props.firebase.messaging;
    //   messaging
    //     .requestPermission()
    //     .then(() => {
    //       return messaging.getToken();
    //     })
    //     .then((data) => {
    //       this.setState({
    //         ...this.state,
    //         msgToken: data
    //       });
    //       console.warn("token :", data);
    //     });
    // }
  }

  handleNavBack = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "top" },
    };
    this.props.history.push(location);
  };

  handleSkip = () => {
    const location = {
      pathname: ROUTES.HOME,
      state: { animation: "top" },
    };
    this.props.history.push(location);
  }

  handleChange = event => {
    event.persist();

    this.setState(
      {
        ...this.state,
        values: {
          ...this.state.values,
          [event.target.name]:
            event.target.type === "checkbox"
              ? event.target.checked
              : event.target.value
        },
        touched: {
          ...this.state.touched,
          [event.target.name]: true
        },
        autherror: null
      },
      () => {
        const errors = validate(this.state.values, schema);
        this.setState({
          ...this.state,
          isValid: errors ? false : true,
          errors: errors || {}
        });
      }
    );
  };


  handleSignIn = async (event) => {
    event.preventDefault();

    const { email, password } = this.state.values;

    this.props.signInStart();

    try {
      // login using token
      const response = await login(email, password);
      console.log("sign in success user :", response);

      // save user to the redux
      this.props.signInSuccess(response);

      // go to landing page
      this.props.history.push(ROUTES.LANDING);

    } catch (err) {
      this.props.signUpFailure(err.message);
      this.setState({
        ...this.state,
        autherror: err
      });
    }
  };

  handleCloseError = () => {
    this.setState({
      ...this.state,
      autherror: null
    });
  };

  render() {
    const { classes, fetching } = this.props;
    const { email, password } = this.state.values;
    const { errors, touched, isValid, autherror } = this.state;

    const hasError = field => (touched[field] && errors[field] ? true : false);

    return (
      <div className={classes.root}>
        <div className={classes.appbar}>
          <BasicAppBar
            width={MAX_ARTICLE_WIDTH}
            title={"Sign In"}
            action={"SKIP"}
            onNavBack={this.handleNavBack}
            onAction={this.handleSkip}
          />
        </div>

        <Box className={classes.container}>
          <Box className={classes.signinForm}>
            <TextField
              className={classes.textField}
              error={hasError("email")}
              fullWidth
              required
              helperText={hasError("email") ? errors.email[0] : null}
              label={
                <span
                  className={hasError("email") ? classes.error : classes.info}
                >
                  Email
                </span>
              }
              type="email"
              name="email"
              autoComplete="email"
              value={email || ""}
              onChange={this.handleChange}
              InputProps={{
                classes: {
                  root: classes.customInput
                }
              }}
            />
            <TextField
              className={classes.textField}
              error={hasError("password")}
              fullWidth
              required
              helperText={hasError("password") ? errors.password[0] : null}
              label={
                <span
                  className={
                    hasError("password") ? classes.error : classes.info
                  }
                >
                  Password
                </span>
              }
              name="password"
              onChange={this.handleChange}
              type="password"
              value={password || ""}
              InputProps={{
                classes: {
                  root: classes.customInput
                }
              }}
            />
          </Box>
          <Box>
            <Typography className={classes.signupline}>
              Don't have an account?&nbsp;&nbsp;
              <Link className={classes.signlink} to={ROUTES.SIGN_UP}>
                Sign Up
              </Link>
            </Typography>
            <Typography className={classes.resetline}>
              Forgot password?&nbsp;&nbsp;
              <Link className={classes.signlink} to={ROUTES.RESET_PASSWORD}>
                Reset Passowrd
              </Link>
            </Typography>
          </Box>
          <Box>
            <Button
              className={classes.submitBtn}
              disabled={!isValid}
              onClick={this.handleSignIn}
            >
              Get Started
            </Button>
          </Box>
        </Box>
        <SnackAlert
          variant={"error"}
          openStats={autherror}
          message={autherror !== null ? autherror.message : ""}
          vertical={"top"}
          horizontal={"center"}
          handleEvent={this.handleCloseError}
        />
        <WaitingDialog open={fetching} />
      </div>
    );
  }
}

SignIn.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  error: state.userState.error,
  errorMsg: state.userState.errorMsg,
  fetching: state.userState.fetching
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles)
)(SignIn);
